/**
 * Colors
 */
$black: #000;
$white: #fff;
$red: #e10f13;
//$yellow: #fec831;
$yellow: #dac287;
$antracit: #272728;

/**
/* Font weight variables
 */
$font-light: 100;
$font-normal: 300;
$font-medium: 500;
$font-semibold: 700;
$font-bold: 900;