/************************************************************************************
FOOTER
*************************************************************************************/

.footer {
  font-size: 14px;
  line-height: 2em;
  font-weight: 400;
  background: #272728;
  color: #8b8e8e;

  .f_1 {
    @include rem(padding, 85px 0 35px 0);
  }

  .f_2 {
    .inner {
      border-top: 1px solid rgba(255,255,255,.1);
      display: flex;
      justify-content: space-between;
      @include rem(padding, 40px 0 15px 0);
    }
  }

  .foot-item {
    @include rem(padding, 30px 0);
  }

  h3 {
    font-size: 14px;
    line-height: 1.3em;
    font-weight: 600;
    @include rem(margin-bottom, 20px);
    color: #c6c7bf;
  }

  ul {
    li {
      margin-bottom: 4px;
      padding: 0 0 0 24px;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 7px;
        width: 12px;
        height: 12px;
        background: url('../img/li-arr.svg') center center no-repeat;
        background-size: cover !important;
      }
    }

    &.unstyled {
      li {
        padding: 0;

        &:before {
          content: none;
        }
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: #8b8e8e;
    text-decoration: none;
    @include transition(all, .3);

    &:hover {
      color: #fff;
    }

    &.hl {
      color: $yellow;

      &:hover {
        color: #fff;
      }
    }
  }

  .logos {
    .item-list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      @include rem(margin, 0 -15px);
    }

    .item {
      @include rem(margin, 5px 0);
      @include rem(padding, 0 15px);

      img {
        //filter: grayscale(100%);
      }
    }
  }

  .mw {
    position: relative;
    color: #c6c6c6;
    display: block;

    &:hover {
      color: #00daee;
    }

    .text {
      position: absolute;
      left: 0;
      top: 0;
      text-indent: -80000px;
      display: block;
    }

    .icon-mw {
      width: 70px;
      height: 30px;
    }
  }

  .orwin {
    filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale"); /* Firefox 10+, Firefox on Android */
    filter: gray; /* IE6-9 */
    -webkit-filter: grayscale(100%); /* Chrome 19+, Safari 6+, Safari 6+ iOS */
    @include transition(all,.3);
    opacity: 0.5;

    &:hover {
      filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale");
      -webkit-filter: grayscale(0%);
      opacity: 1;
    }

    .text {
      position: absolute;
      left: 0;
      top: 0;
      text-indent: -80000px;
      display: block;
    }
  }
}

.social-nav {
  ul {
    display: flex;
    @include rem(margin, 0 -5px);

    li {
      @include rem(margin, 4px 0);
      @include rem(padding, 0 5px);

      &:before {
        content: none;
      }

      a {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        @include rem(width, 40px);
        @include rem(height, 40px);
        border-radius: 50%;
        background: #000;
        color: #fff;

        .icon {
          @include rem(width, 25px);
          @include rem(height, 25px);
        }
      }

      &.fb {
        a {
          background: #3b5998;
        }
      }

      &.tw {
        a {
          background: #39a2f2;
        }
      }

      &.ig {
        a {
          background: #d6249f;
          background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
        }
      }
    }
  }
}