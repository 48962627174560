/************************************************************************************
MEDIA QUERIES
*************************************************************************************/

/*========================= Viewport width 1399px and lower =========================*/

@media screen and (max-width: 1399px) {
  .post {
    .block-main-img {
      margin-right: 0;
    }
  }
}

/*========================= Viewport width 1199px and lower =========================*/

@media screen and (max-width: 1199px) {
  html {
    font-size: 15px;
  }

  h1, .h1 {
    @include rem(font-size, 30px);

    &.big {
      @include rem(font-size, 40px);
      line-height: 1.3em;    
    }
  }

  h2, .h2 {
    @include rem(font-size, 28px);

    &.huge {
      @include rem(font-size, 60px);
    }

    &.big {
      @include rem(font-size, 28px);
    }
  }

  .post {
    .block-main-img {
      margin-right: 0;
    }
  }

  .header {
    .logo {
      max-width: 160px;
    }
  }

  .main-nav {
    ul {
      @include rem(margin, 0 -8px);

      li {
        @include rem(padding, 0 8px);

        a {
          font-size: 13px;
        }
      }
    }
  }

  .video {
    .play {
      @include rem(width, 92px);
      @include rem(height, 63px);
    }
  }

  .calendar-head {
    .calendar-switch {
      .head, .head.small {
        @include rem(font-size, 25px);
      }
    }
  }
}

/*========================= Viewport width 991px and lower =========================*/

@media screen and (max-width: 991px) {
  html {
    font-size: 14px;
  }

  h2 {
    &.huge {
      @include rem(font-size, 48px);
    }
  }

  .block {
    @include rem(padding, 60px 0);
  }

  .spacer {
    @include rem(height, 60px);
  }

  .header {
    .inner {
      @include rem(height, 100px);
    }

    .search-trig {
      margin-left: 0;
    }
  }

  .teaser-hp {
    @include rem(padding-bottom, 60px);

    .text {
      @include rem(width, 370px);
      @include rem(flex, 0 0 370px);
    }
  }

  .main-nav {
    display: none;
  }

  .nav-btn {
    display: block;
  }

  .mobile-nav-wrap {
    display: block;
  }

  .col2-l-set {
    .content-col {
      padding-left: 40px;
    }

    .side-col {
      @include rem(max-width, 260px);
      @include rem(flex, 0 0 260px);
    }
  }

  .side-col-r {
    padding-left: 0;
    @include rem(padding-top, 80px);
  }

  .interests {
    .item-inner {
      @include rem(padding, 30px);
    }

    .main {
      .item-inner {
        .text {
          @include rem(padding, 0 30px 30px 30px);
        }
      }
    }
  }

  .news {
    .item-inner {
      @include rem(padding, 30px);
    }
  }

  .instagram {
    @include rem(margin-bottom, 40px);
  }

  .facebook {
    @include rem(margin-bottom, 40px);
  }

  .persons {
    .item-inner {
      //display: block;
    }

    .item {
      .img {
        //display: block;
        //@include rem(margin-bottom, 20px);
        order: 0;
        max-width: 100px;
        flex: 0 0 100px;
      }

      .text {
        padding-right: 0;
        padding-left: 30px;
      }
    }
  }

  .search-input {
    @include rem(margin, 0 30px);
  }

  .date-cell {
    .cell-content {
      @include rem(padding, 15px);
    }
  }

  .box, .box-hl, .box-std {
    @include rem(padding, 30px 25px);
  }
}

/*========================= Viewport width 767px and lower =========================*/

@media screen and (max-width: 767px) {
  html {
    //font-size: 14px;
  }

  .block {
    @include rem(padding, 40px 0);
  }

  .spacer {
    @include rem(height, 40px);
  }

  .teaser-hp {
    @include rem(padding-bottom, 40px);

    .teaser-content {
      flex-wrap: wrap;
    }

    .img {
      order: 0;
      @include rem(margin, 0 0 20px 0);
    }

    .text {
      order: 1;
      max-width: 100%;
      flex: 0 0 100%;

      .head {
        margin-right: 0;
      }
    }
  }

  .col2-l-set {
    //flex-wrap: wrap;
    display: block;

    .content-col {
      padding-left: 0;
      //flex-grow: 0;
      display: block;
    }

    .side-col {
      max-width: 100%;
      flex: 0 0 100%;
      display: block;
    }
  }

  .side-nav {
    display: none;
  }

  .interests {
    margin: 0 -12px;
    flex-wrap: wrap;

    .main {
      flex: 0 0 100%;
      max-width: 100%;

      .item-inner {
        .text {
          padding: 0 15px 15px 15px;  
        }
      }
    }

    .side {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .item-inner {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .table-wrap {
    overflow-x: scroll;
    max-width: 100%;

    table {
      width: 750px;
    }
  }

  .login-box {
    .box-text {
      @include rem(padding, 40px);
    }
  }
}

/*========================= Viewport width 575px and lower =========================*/

@media screen and (max-width: 575px) {
  html {
    font-size: 13px;
  }

  body {
    @include rem(font-size, 18px);
  }

  h2 {
    &.huge {
      @include rem(font-size, 40px);
    }
  }

  .teaser-hp {
    .text {
      h2 {
        .more {
          top: -3px;
        }
      }
    }
  }

  .breadcrumbs {
    ul {
      li {
        display: none;

        &:last-child,
        &:nth-last-child(2) {
          display: block;
        }
      }
    }
  }

  .footer {
    .f_1 {
      @include rem(padding, 40px 0);
    }
  }

  .post {
    .box, .box-hl {
      padding: 30px 15px;

      .files-list {
        margin: 30px -15px -30px -15px;

        .item-inner {
          padding: 15px;
        }
      }
    }
  }

  .persons {
    .item {
      .img {
        max-width: 80px;
        flex: 0 0 80px;
      }

      .text {
        padding-left: 20px;
      }
    }
  }

  .photogallery {
    .item-list {
      margin: 0 -10px;

      .item {
        padding: 0 10px;
      }
    }
  }

  .instagram {
    mx-width: 360px;
  }

  .files-list,
  .big-list,
  .medium-list {
    .item-inner {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  .big-list {
    &.flat-list {
      .item {
        .img {
          display: none;
        }
      }
    }
  }

  img.right {
    float: none;
    padding: 0;
    margin-bottom: 10px;
  }

  img.left {
    float: none;
    padding: 0;
    margin-bottom: 10px;
  }

  .header {
    .search-input, .search {
      //display: none !important;
    }

    .search-input.active {
      position: absolute;
      left: 0;
      @include rem(top, 100px);
      @include rem(height, 100px);
      padding: 15px 15px;
      width: 100%;
      margin: 0;
      //margin: 15px 0;
      background: #fff;

      form {
        position: relative;
      }

      .btn {
        right: 0;
      }
    }
  }

  .main-nav-dropdown {
    &#vyhledavani {
      @include rem(top, 100px);
    }
  }

  .post {
    table {
      &.phone-table {
        tr {
          display: block;

          &:first-child {
            border-top: 1px solid $red;
          }
        }

        td, th {
          display: block;
          width: 100%;
        }

        th, thead {
          display: none;
        }

        td {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
        }

        td.name:before {
          content: 'Jméno: ';
          color: $red;
          font-weight: 600;
        }

        td.department {
          text-align: right;
          flex-wrap: nowrap;

          &:before {
            content: 'Funkce: ';
            color: $red;
            font-weight: 600;
            //float: left;
          }
        }

        td.phone {
          display: block;
          text-align: right;

          a {
            display: block;
            margin-bottom: 10px;

            &:last-child {
              margin-bottom: 0;
            }
          }

          &:before {
            content: 'Telefon: ';
            color: $red;
            font-weight: 600;
            float: left;
          }
        }
      }
    }
  }

  .login-box {
    max-width: 467px;
    margin: 0 auto;

    .box-img {
      order: 1;
      display: none;
    }

    .box-text {
      order: 0;
    }
  }

  .mobile-hidden {
    display: none !important;
  }
}