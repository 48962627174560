/************************************************************************************
RESET
*************************************************************************************/

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, figure, footer, header, hgroup, menu, nav, section, menu,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}

ol, ul { list-style: none; }

blockquote, q { quotes: none; }
blockquote:before, blockquote:after,
q:before, q:after { content: ''; content: none; }

//:focus { outline: 0; }

table { border-collapse: collapse; border-spacing: 0; }

article, aside, figure, footer, header, hgroup, nav, section, img { display: block; }

input[type="radio"] { vertical-align: text-bottom; }
input[type="checkbox"] { vertical-align: bottom; *vertical-align: baseline; }

label, input[type=button], input[type=submit], button { cursor: pointer; overflow: visible; }

em, i { font-weight: inherit; }

/************************************************************************************
GENERAL
*************************************************************************************/

body {
	font-family: Montserrat,sans-serif;
	@include rem(font-size, 16px);
	line-height: 2.0em;
	font-weight: 500;
	color: #000;
	background: #f5f5f5;
	margin: 0;
	padding: 0;
	min-height: 100%;
	-webkit-text-size-adjust: 100%;

	&.searching {
		.content, .footer {
			position: relative;

			&:after {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				z-index: 10;
				width: 100%;
				height: 100%;
				background: rgba(0,0,0,.5);
				animation: overlayFadeIn .5s;
			}
		}
	}
}

@keyframes overlayFadeIn {
  0% {
    display: none;
    background: rgba(0,0,0,0);
  }
  5% {
    display: block;
    background: rgba(0,0,0,0);
  }
  100% {
    display: block;
    background: rgba(0,0,0,.5);
  }
}

/* Box sizing */

*, *:after, *:before {
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
}

/* Anchors */

a {
	color: $red;
	text-decoration: underline;
	@include transition(all, .3);

	&:hover {
		text-decoration: none;
	}
}

/* Heading */

h1, .h1 {
	font-family: Montserrat,sans-serif;
	@include rem(font-size, 40px);
	line-height: 1.4em;
	font-weight: 700;
	color: #000;
	@include rem(margin, 0 0 30px 0);

	&.big {
		@include rem(font-size, 64px);
		line-height: 1.125em;
	}
}

h2, .h2 {
	font-family: Montserrat,sans-serif;
	@include rem(font-size, 30px);
	line-height: 1.3em;
	font-weight: 700;
	color: #000;
	@include rem(margin, 0 0 20px 0);

	&.small {
		@include rem(font-size, 19px);
	}

	&.big {
		@include rem(font-size, 40px);
	}

	&.huge {
		@include rem(font-size, 64px);
    letter-spacing: -.05em;
    line-height: 1.1em;
	}
}

h3, .h3 {
	font-family: Montserrat,sans-serif;
	@include rem(font-size, 25px);
	line-height: 1.3em;
	font-weight: 700;
	color: #000;
	@include rem(margin, 0 0 10px 0);

	&.medium {
		@include rem(font-size, 28px);
		line-height: 1.4em;
		font-weight: 700;
	}

	&.small {
		@include rem(font-size, 17px);
		line-height: 1.4em;
		font-weight: 700;
	}
}

h4, .h4 {
	font-family: Montserrat,sans-serif;
	@include rem(font-size, 20px);
	line-height: 1.3em;
	font-weight: 700;
	color: #000;
	@include rem(margin, 0 0 10px 0);
}

h5, .h5 {
	font-family: Montserrat,sans-serif;
	@include rem(font-size, 20px);
	line-height: 1.3em;
	font-weight: 400;
	color: #000;
	@include rem(margin, 0 0 10px 0);
}

h6, .h6 {
	font-family: Montserrat,sans-serif;
	@include rem(font-size, 20px);
	line-height: 1.3em;
	font-weight: 400;
	color: #000;
	@include rem(margin, 0 0 10px 0);
}

.suphead {
	@include rem(font-size, 17px);
	color: #8995a6;
	text-transform: uppercase;
	letter-spacing: 0.02em;
}

/* Paragraphs */

p {
	margin: 0 0 20px 0;

	&.big {
		@include rem(font-size, 18px);
		line-height: 1.78em;
		font-weight: 600;
	}
}

address, cite {
	font-style: normal;
}

/* Lists */

ul {
	list-style: none;
	@include rem(margin, 0 0 20px 0);
	padding: 0;

	li {
		@include rem(padding, 0 0 0 20px);
		@include rem(margin, 0 0 5px 0);
	}

	&.unstyled li {
		padding: 0;
		background: none;
	}
}

ol {
	@include rem(margin, 0 0 20px 0);
	padding: 0;
	counter-reset: counter;
	margin-left: 0;

	ol {
		padding-left: 20px;
	}

	li {
		margin: 0;		
		padding: 0;
		counter-increment: counter;
		position: relative;

		&:before {
			font-weight: 700;
			content: counters(counter, ".")". ";
		}

		ul {
			padding-left: 20px;
			margin-bottom: 10px;

		  li {
		  	counter-increment: none;

		  	&:before {
		  		content: '';
		  	}
		  }
		}
	}
}

dl {
	@include rem(margin, 0 0 20px 0);
}

dt {
	font-weight: bold;
}

/* Various */

sup, sub {
	font-size: 11px;
	height: 0;
	line-height: 1;
	vertical-align: baseline;
	_vertical-align: bottom;
	position: relative;
}

sup {
	bottom: 1ex;
}

sub {
	top: .5ex;
}

hr {
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid #ccc;
	margin: 1em 0;
	padding: 0;
}

img {
	max-width: 100%;
	height: auto;

	&.right {
		float: right;
		@include rem(padding-left, 20px);
	}

	&.left {
		float: left;
		@include rem(padding-right, 20px);
	}
}

.bold, b, strong {
	font-weight: 700;
}

.italic, i, em {
	font-style: italic;
}

.nowrap, .nobr {
	white-space: nowrap !important;
}

/************************************************************************************
CONTENT
*************************************************************************************/

.content {
	padding: 0;
	@include transition(all, .3);
}

/* Block */

.block {
	@include rem(padding, 80px 0);
	position: relative;

	&.narrow {
		@include rem(padding, 40px 0);
	}

	&.alt {
		background: $antracit;
	}

	&.alpha {
		padding-top: 0;
	}

	&.omega {
		padding-bottom: 0;
	}
}

.anchor {
	position: absolute;
	left: 0;
	@include rem(top, 0);
}

.invert {
	h2 {
		color: #c6c7bf;
	}
}

/* Various */

.section-head {
	@include rem(margin-bottom, 40px);
	text-align: center;

	h1, h2, h3 {
		margin-bottom: 0;
	}
}

.intro {
	max-width: 500px;
	@include rem(margin, 0 auto 40px auto);
	text-align: center;

	&.wide {
		@include rem(max-width, 750px);
	}
}

/* Pager */

.pager {
	@include rem(margin, 30px 0);
	text-align: center;
	position: relative;
	display: flex;
	justify-content: space-between;

	ul {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 0 -5px !important;
	}

	li {
		padding: 0 5px !important;
		background: none;

		&:before {
			content: none !important;
		}

		&.active {
			a {
				background: $yellow;
				color: #000;
			}
		}

		a {
			display: flex;
			align-items: center;
			justify-content: center;
			@include rem(width, 36px);
			@include rem(height, 36px);
			background: #dfe0e2;
			color: #000;
			text-align: center;
			text-decoration: none;

			&:hover {
				background: $yellow;
			}
		}
	}

	.prev {
		@include rem(width, 36px);
		@include rem(height, 36px);
		background: #dfe0e2;
		color: #000;
		position: relative;
		margin-right: 10px;

		&:before {
			content: '';
			display: block;
			border: none;
			border-right: 2px solid #000;
			border-bottom: 2px solid #000;
			@include rotate(135deg);
			width: 10px;
			height: 10px;
			margin-top: -5px;
			margin-left: -5px;
			top: 50%;
			left: 50%;
			position: absolute;
			@include transition(all, .5);
		}

		&:hover {
			background: $yellow;
		}
	}

	.next {
		@include rem(width, 36px);
		@include rem(height, 36px);
		background: #dfe0e2;
		color: #000;
		position: relative;
		margin-left: 10px;

		&:before {
			content: '';
			display: block;
			border: none;
			border-right: 2px solid #000;
			border-bottom: 2px solid #000;
			@include rotate(-45deg);
			width: 10px;
			height: 10px;
			margin-top: -5px;
			margin-left: -8px;
			top: 50%;
			left: 50%;
			position: absolute;
			@include transition(all, .5);
		}

		&:hover {
			background: $yellow;
		}
	}

	.counter {
		display: none;
	}
}

/* Gmap */

.gmap-wrap {
	position: relative;

	.gmap {
		height: 600px;
		background: #e3e3e3;
	}

	img {
		max-width: inherit !important;
	}
}

/*================================= Various Content =================================*/

.last {
	margin-bottom: 0 !important;
}

/* Buttons */

.btn,
.sendbutton {
	font-size: 12px;
	font-family: Montserrat,sans-serif;
	line-height: 1.3em;
	font-weight: 700;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	background: $red;
	color: #fff;
	@include rem(padding, 0 24px);
	@include rem(height, 48px);
	text-decoration: none;
	text-transform: uppercase;
	letter-spacing: .1em;
	position: relative;
	border: 1px solid $red;
	border-radius: 2px;
	text-align: center;
	@include transition(all, .5);

	.icon-arr-r {
		@include rem(width, 12px);
		@include rem(height, 12px);
		margin-left: 10px;
		position: relative;
		left: 0;
		@include transition(all, .3);
	}

	&:hover {
		background-color: #d41118;
		border-color: #d41118;
		color: #fff;

		.icon-arr-r {
			left: 5px;
		}
	}

	&.line {
		background: none;
		color: #f25a5f;
		border: 1px solid #f25a5f;

		&:hover {
			//background: #ed1c24;
			color: #d41118;
			border-color: #d41118;
			color: #d41118;
		}
	}
}

/* SVG icons */

.icon {
	width: 20px;
	height: 20px;
	display: inline-block;
	vertical-align: top;
	position: relative;
	fill: currentColor;
}

/* Cookie Notice */

.cookie-notice {
	font-size: 14px;
	position: fixed;
	z-index: 10000;
	bottom: 0;
	width: 100%;
	padding: 10px 15px;
	background: rgba(0,0,0,0.90);
	color: #CCC;
	text-align: center;
}

.cookie-button {
	display: inline-block;
	padding: 2px 10px;
	margin-left: 25px;
	background: #02ad65;
	color: #fff;
	text-align: center;

	&:hover {
		background: #90ca16;
		color: #fff;
	}
}

.cookie-test {
	position: fixed;
	z-index: 10000;
	bottom: 0;
	width: 150px;
	padding: 25px 15px;
	background: rgba(50,50,50,0.95);
	color: #CCC;
	text-align: center
}

/* FY Apple! */

textarea,
input.text,
input[type="text"],
input[type="button"],
input[type="submit"],
.btn {
	-webkit-appearance: none;
}

/************************************************************************************
ENTRY
*************************************************************************************/

.entry {
	@include rem(margin, 0 0 40px 0);

	h2, h3 {
		margin-top: 30px;

		&::first-child {
			margin-top: 0;
		}
	}

	ul {
		li {
			position: relative;

			&:before {
				content: '';
				position: absolute;
				left: 0;
				@include rem(top, 12px);
				width: 4px;
				height: 4px;
				display: block;
				background: #000;
			}
		}
	}
}

/************************************************************************************
TABLES
*************************************************************************************/
/*
.entry {
	table {
		width: 100%;
		@include rem(margin, 0 0 30px 0);

		th {
			font-weight: normal;
			border-bottom: 1px solid #c9cacb;
			@include rem(padding, 10px 20px);
			background: #e3e3e3;
			color: #000;
		}

		td {
			border-bottom: 1px solid #c9cacb;
			@include rem(padding, 10px 20px);
		}
	}
}
*/
/************************************************************************************
FORMS
*************************************************************************************/

form.std {
	label {
		font-size: 12px;
		line-height: 1.3em;
		color: #8e8d8d;
		font-weight: 600;
		text-transform: uppercase;
		@include rem(margin, 0 0 10px 0);
		display: block;
	}

	input.text {
		font-size: 16px;
		font-family: Montserrat,sans-serif;
		line-height: 1.3em;
		font-weight: 400;
		color: #000;
		background: #f7f7f7;
		border: 1px solid #e7e7e7;
		@include rem(padding, 0 16px);
		@include rem(height, 48px);
		width: 100%;
		border-radius: 3px;

		&:focus {
			outline: none;
		}

		&.error {
			background: #fcf4f4;
			border-color: #be1e19;
		}

		&.req {
			background-image: url('../img/req.svg');
			background-position: right center;
			background-repeat: no-repeat;
		}
	}

	textarea {
		font-size: 16px;
		font-family: Montserrat,sans-serif;
		line-height: 1.3em;
		font-weight: 400;
		color: #000;
		resize: vertical;
		background: #f7f7f7;
		border: 1px solid #e7e7e7;
		@include rem(padding, 10px 15px);
		width: 100%;
		min-width: 100%;
		max-width: 100%;
		height: 150px;
		min-height: 150px;
		max-height: 300px;
		border-radius: 3px;

		&:focus {
			outline: none;
		}

		&.req {
			background-image: url('../img/req.svg');
			background-position: right center;
			background-repeat: no-repeat;
		}
	}

	select {
		@include rem(font-size,17px);
		line-height: 1.3em;
		color: #000;
		width: 100%;
		@include rem(padding, 2px 10px);
		@include rem(height, 48px);
		border: 1px solid #ececec;

		&[disabled] {
			opacity: .5;
		}
	}

	option {
		@include rem(font-size,17px);
		line-height: 1.3em;
		@include rem(padding, 2px 10px);
	}

	input.text[required="required"] {
		//background-image: url('../img/req.png');
		//background-position: right center;
		//background-repeat: no-repeat;
	}

	.cell {
		@include rem(margin-bottom, 20px);
		position: relative;
		text-align: left;
	}

	.toggle {
		position: relative;
		@include rem(padding, 0 0 0 35px);

		label {
			line-height: 1.6em;
			display: inline-block;
			vertical-align: top;
			margin: 0;
			@include rem(padding-top, 3px);
		}

		input {
			margin: 0;
			position: absolute;
			left: 0;
			@include rem(top, 2px);
		}
	}

	.radio, .checker {
		position: absolute;
		left: 0;
		top: 0;
	}

	.checker {
    position: absolute;
    left: 0;
    top: 0;
    background: url('../img/checker.svg') 0 0 no-repeat;
    background-color: #f7f7f7;
    @include rem(background-position, 0 -23px);
    @include rem(background-size, 23px);
    @include transition(all, .5);
    border: 1px solid #e7e7e7;
    @include rem(width, 26px);
    @include rem(height, 26px);
    @include rem(margin, 0);
    //border-radius: 50%;
    cursor: pointer;

    input {
    	opacity: 0;
    	position: absolute;
    	left: 0;
    	top: 0;
    	width: 100%;
    	height: 100%;
    	margin: 0;
    	cursor: pointer;
    }

    &.active {
      @include rem(background-position, 0 0);
      background-color: transparent;
    }

    &.has-error {
      border-color: #be1e19;
    }
  }
}

form.form-footer {
	input.text, textarea {
		color: #fff;
		border: 1px solid #8b8e8e;
		background: none;
	}

	.toggle {
		.checker {
			border: 1px solid #8b8e8e;
			background-color: transparent;
		}
	}
}

form.form-search-header {
	input.text {
		font-size: 16px;
		//@include rem(font-size, 17px);
		font-weight: 500;
		color: #000;
    @include rem(height, 62px);
    border: 1px solid #cdcece;
    background: #fff;
    width: 100%;

    &:focus {
    	border: 1px solid #cdcece;
    	outline: 0;
    }
  }

  .btn {
    position: absolute;
    right: 0;
    top: 0;
    @include rem(width, 62px);
    height: 100%;
    background: none;
    border: none;
    padding: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    .icon {
    	color: #000;
    	width: 33px;
    	height: 33px;
    }
  }
}

form.form-event {
	max-width: 744px;
	margin: 0 auto;

	.btn-set {
		display: flex;
		justify-content: center;
	}
}

form.form-calendar-filter {
	input.text {
		background: #fff;
	}
}

form.form-login {
	h1 {
		line-height: 1.1em;
	}

	.btn {
		width: 100%;
		@include rem(margin-bottom, 30px);

		.icon {
			margin-left: 10px;
			width: 15px;
			height: 15px;
		}
	}

	.btn-set {
		text-align: center;
	}

	.forgot {
		@include rem(font-size, 12px);
		line-height: 1.3em;
		font-weight: 600;
		text-transform: uppercase;
		letter-spacing: .15em;
		margin: 0;

		.icon {
			margin-left: 10px;
			width: 15px;
			height: 15px;
		}

		a {
			text-decoration: none;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}

.flash-message {
	padding: 0;
	background: #ebf5bc;
	border: 1px solid #f1c7d2;
	color: #46b450;
	@include rem(padding, 15px 20px);
	@include rem(margin, 0 0 30px 0);

	&.error {
		border: 1px solid #f1c7d2;
		background: #fae7ea;
		color: #e5547a;
	}
}

/************************************************************************************
TABS
*************************************************************************************/

.tabs {
	position: relative;

	.tab-nav {
		@include rem(margin-bottom, 40px);
		overflow: hidden;

		ul {
			@include rem(margin, -10px -30px);
			padding: 0;
			display: flex;
			flex-wrap: wrap;
		}

		li {
			list-style-type: none;
			background: none;
			@include rem(padding, 0 30px);
			@include rem(margin, 10px 0);

			a {
				@include rem(font-size, 32px);
				line-height: 1.3em;
				font-weight: 700;
				display: block;
				text-decoration: none;
				color: #8c8c8c;
				display: block;
				@include rem(padding-bottom, 15px);
				position: relative;

				&:after {
					content: '';
					position: absolute;
					left: 0;
					bottom: 0;
					background: $yellow;
					width: 0;
					height: 7px;
					display: block;
				}

				&:hover {
					color: $red;
				}
			}

			&.glide__bullet--active {
				a {
					color: #000;

					&:after {
						width: 100%;
					}
				}
			}
		}
	}

	.tab-content {
		.tab {
			width: 100%;
			height: 0;

			&.glide__slide--active {
				height: auto;
			}
		}
	}

	.tab-main {
		//margin: 0 -13px;
	}

	.glide__track {
		//overflow: visible;
	}
}

/************************************************************************************
SIMPLE LIGHTBOX
*************************************************************************************/

html.slbActive {
	overflow: hidden;
}

body {
	.slbArrow {
		font-size: 15px;

		&.prev {
			background: #fff;
			width: 40px;
			height: 40px;
			margin-top: -20px;
			opacity: 1;
			@include transition(all, .5);

			&:before {
				border: none;
				border-right: 2px solid #000;
				border-bottom: 2px solid #000;
				@include rotate(135deg);
				width: 15px;
				height: 15px;
				margin-top: -7.5px;
				margin-left: -6px;
				@include transition(all, .5);
			}

			&:hover {
				opacity: 1;
				background: #000;

				&:before {
					border-right: 2px solid #fff;
					border-bottom: 2px solid #fff;
				}
			}
		}

		&.next {
			background: #fff;
			width: 40px;
			height: 40px;
			margin-top: -20px;
			opacity: 1;
			@include transition(all, .5);

			&:before {
				border: none;
				border-right: 2px solid #000;
				border-bottom: 2px solid #000;
				@include rotate(-45deg);
				width: 15px;
				height: 15px;
				margin-top: -7.5px;
				@include transition(all, .5);
			}

			&:hover {
				opacity: 1;
				background: #000;

				&:before {
					border-right: 2px solid #fff;
					border-bottom: 2px solid #fff;
				}
			}
		}
	}

	.slbCloseBtn {
		font-size: 30px;
		line-height: 40px;
		font-weight: 400;
		font-family: peachy-keen-jf,sans-serif;
		color: #fff;
		width: 40px;
		height: 40px;
		background: #fff;
		color: #000;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		padding: 0;
		right: 0;
		top: 0;
		position: fixed;
		font-size: 0;
		@include transition(all, .5);

		&:after {
			content: '';
			width: 24px;
			height: 2px;
			position: absolute;
			left: 50%;
			top: 50%;
			margin-left: -12px;
			margin-top: -1px;
			display: block;
			background: #000;
			@include rotate(45deg);
			@include transition(all, .5);
		}

		&:before {
			content: '';
			width: 2px;
			height: 24px;
			position: absolute;
			left: 50%;
			top: 50%;
			margin-top: -12px;
			margin-left: -1px;
			display: block;
			background: #000;
			@include rotate(45deg);
			@include transition(all, .5);
		}

		&:hover {
			opacity: 1;
			background: #000;
			color: #fff;

			&:after {
				background: #fff;
				@include rotate(-45deg);
			}

			&:before {
				background: #fff;
				@include rotate(-45deg);
			}
		}
	}

	.slbImage {
		@include rem(padding, 15px 0);
	}

	.slbImageWrap {
		&:after {
			content: none;
		}
	}

	.slbCaption {
		@include rem(font-size, 16px);
		position: static;
		overflow: visible;
		white-space: normal;
		padding-top: 0;
	}
}

/************************************************************************************
TIPS
*************************************************************************************/

[data-tippy-root] {
	pointer-events: auto !important;
}

.tippy-popup {
	display: none;
}

.tippy-box {
	background: #fff;
	color: #000;
	border-radius: 0;
	box-shadow: 0 0 20px 0 rgba(0,0,0,.2);
}

.tippy-arrow {
	color: #fff;

	&:before {
		transform: scale(1.5);
	}
}

.tippy-content {
	padding: 20px;
	border-radius: 0;

	.calendar-events {
		margin-bottom: 0;
	}
}

/************************************************************************************
MODAL
*************************************************************************************/

.modal {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: rgba(0,0,0,.5);
  overflow: auto;
  align-items: center;
  justify-content: center;

  &.active {
    display: flex;
  }

  .modal-content {
  	@include rem(padding, 50px);

  	&.alt {
  		background: #f9fbfc;
  	}
  }

  .modal-box {
	  @include rem(font-size, 18px);
	  @include rem(max-width, 400px);
	  background-color: #fff;
	  line-height: 1.5em;
	  margin: auto;
	  position: relative;

	  .modal-close {
	    position: absolute;
	    right: 0;
	    top: 0;
	    text-decoration: none;
	    @include rem(width, 40px);
	    @include rem(height, 40px);
	    display: flex;
	    justify-content: center;
	    align-items: center;
	    background: #000;
	    color: #fff;

	    &:after {
	    	content: '×';
	    	@include rem(font-size, 30px);
	    	position: absolute;
	    	left: 0;
	    	top: 0;
	    	width: 100%;
	    	height: 100%;
	    	display: flex;
	    	justify-content: center;
	    	align-items: center;
	    	color: #fff;
	    }

	    .icon {
	    	@include rem(width, 18px);
	    	@include rem(height, 18px);
	    	transform: rotate(0deg);
	    	@include transition(all, .3);
	    }

	    &:hover {
	    	background: #fff;

	    	&:after {
	    		color: #000;
	    	}

	    	.icon {
	    		transform: rotate(90deg);
	    	}
	    }
	  }

	  &.modal-order {
			width: 100%;
			@include rem(max-width, 580px);
			background: #e5e5e5;
		}
	}

	.modal-box-wrap {
	  width: 100%;
	  //max-width: 500px;
	  @include rem(padding, 50px 15px 15px);
	  margin: auto;
	  overflow: hidden;
	  position: relative;
	  animation-name: animateTop;
	  animation-duration: .4s;
	}
}

@keyframes animateTop {
  from {
    top: -300px;
    opacity: 0;
  }

  to {
    top: 0;
    opacity: 1;
  }
}

/************************************************************************************
SLIDERS
*************************************************************************************/

/* Teaser slider */

.teaser-slider {
	position: relative;

	.glide__slide {
		padding: 0;
	}
}

/************************************************************************************
CLEARFIX
*************************************************************************************/

/* http://www.yuiblog.com/blog/2010/09/27/clearfix-reloaded-overflowhidden-demystified */

.clearfix:before,
.clearfix:after {
	content: '\0020';
	display: block;
	overflow: hidden;
	visibility: hidden;
	width: 0;
	height: 0;
}

.clearfix:after {
	clear: both;
}

.clearfix {
	zoom: 1;
}

.embed-container {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
	max-width: 100%;
}

.embed-container iframe, .embed-container object, .embed-container embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}