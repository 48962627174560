/************************************************************************************
HEADER
*************************************************************************************/

.header {
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 300;

  .h_1 {
    position: relative;
    z-index: 15;
    background: #fff;
    border-top: 8px solid #e10f13;
    border-bottom: 1px solid #ececec;
  }

  .h_2 {
    position: relative;
    z-index: 10;
  }

  .inner {
    @include rem(height, 118px);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header-r {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    flex-grow: 1;
  }

  &.dropdown-opened {
    background: #fff;

    .main-nav {
      & > ul {
        & > li.active {
          & > a {
            &:after {
              //background: #000;
            }
          }
        }
      }
    }
  }
}

.notification-bar {
  position: relative;

  color: #fff;

  a {
    color: #fff;
  }

  .notificaton-trigger {
    display: block;
  }

  .bar-inner {
    @include rem(font-size, 15px);
    line-height: 1.5em;
    position: relative;
    background: #e10f13;
    
    .text {
      padding: 21px 15px 21px 55px;
      display: block;
    }

    &:before {
      content: 'i';
      color: #fff;
      font-family: Georgia,serif;
      font-size: 16px;
      font-weight: 600;
      font-style: italic;
      display: inline-flex;
      width: 25px;
      height: 25px;
      line-height: 25px;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      border: 2px solid #fff;
      position: absolute;
      left: 15px;
      top: 18px;
    }

    .close {
      width: 20px;
      height: 20px;
      position: absolute;
      right: 0;
      top: 15px;
      //background: #fff;
      @include rotate(45deg);

      &:after {
        content: '';
        position: absolute;
        left: 50%;
        margin-left: -1px;
        width: 2px;
        height: 20px;
        background: #fff;
      }

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -1px;
        width: 20px;
        height: 2px;
        background: #fff;
      }
    }

    .notification-trigger {
      display: none;
    }
  }

  .notification-trigger {
    width: 40px;
    height: 30px;
    position: absolute;
    right: 0;
    top: 0;
    background: $red;
    color: #fff;
    display: block;

    &:before {
      content: 'i';
      color: #fff;
      font-family: Georgia,serif;
      font-size: 16px;
      font-weight: 600;
      font-style: italic;
      display: inline-flex;
      width: 20px;
      height: 20px;
      line-height: 20px;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      border: 2px solid #fff;
      position: absolute;
      left: 50%;
      top: 50%;
      @include translate(-50%,-50%);
    }
  }
}

.notification-bar-alt {
  position: relative;
  z-index: 30;
  background: #fef8e5;
  color: $red;
  text-align: center;

  a {
    color: $red;
  }

  .bar-inner {
    @include rem(font-size, 15px);
    line-height: 1.5em;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    
    .text {
      padding: 21px 15px 21px 15px;
    }

    &:before {
      content: 'i';
      color: $red;
      font-family: Georgia,serif;
      font-size: 16px;
      font-weight: 600;
      font-style: italic;
      display: inline-flex;
      width: 25px;
      height: 25px;
      flex: 0 0 25px;
      line-height: 25px;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      border: 2px solid $red;
      //position: absolute;
      //left: 15px;
      //top: 18px;
    }

    .close {
      width: 20px;
      height: 20px;
      position: absolute;
      right: 0;
      top: 15px;
      @include rotate(45deg);

      &:after {
        content: '';
        position: absolute;
        left: 50%;
        margin-left: -1px;
        width: 2px;
        height: 20px;
        background: $red;
      }

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -1px;
        width: 20px;
        height: 2px;
        background: $red;
      }
    }
  }
}

.search-trig {
  @include rem(width, 32px);
  @include rem(height, 32px);
  display: block;
  @include rem(margin-left, 35px);
  color: #000;

  &:hover {
    color: $red;
  }

  .icon {
    @include rem(width, 32px);
    @include rem(height, 32px);
    //background: green;
  }

  .icon-close {
    display: none;
  }

  &.active {
    .icon-search {
      display: none;
    }

    .icon-close {
      display: block;
    }
  }
}

.search-input {
  position: relative;
  display: none;
  width: 100%;
  max-width: 0;
  @include transition(all, .5);

  input.text {
    max-width: 0;
    @include transition(all, .5);
  }

  &.active {
    display: block;
    max-width: 648px;
    animation: showSearch .5s;

    input.text {
      max-width: 100%;
    }
  }
}

.main-nav {
  vertical-align: top;
  text-align: left;
  display: flex;
  height: 100%;

  &.hidden {
    display: none;
  }

  & > ul {
    display: flex;
    align-items: stretch;
    @include rem(margin, 0 -13px);

    & > li {
      @include rem(padding, 0 13px);
      margin: 0;
      display: flex;

      &.has-subnav {
        a {
          padding-right: 12px;

          &:before {
            content: '';
            width: 6px;
            height: 6px;
            display: block;
            border-left: 2px solid #000;
            border-bottom: 2px solid #000;
            @include rotate(-45deg);
            position: absolute;
            @include rem(right, 0);
            top: 50%;
            margin-top: -4px;
            @include transition(all, .3);
          }
        }

        &.dropdown-opened {
          a {
            &:before {
              @include rotate(135deg);
              margin-top: -1px;
            }

            &:after {
              width: 100%;
            }
          }
        }
      }

      & > a {
        font-size: 14px;
        font-weight: 600;
        color: #000;
        text-decoration: none;
        position: relative;
        display: flex;
        align-items: center;
        @include transition(all, .2);
        white-space: nowrap;

        &:after {
          content: '';
          width: 0;
          height: 8px;
          background: $yellow;
          position: absolute;
          left: 50%;
          bottom: 0;
          @include translate(-50%,0);
        }

        &:hover:after {
          width: 100%;
          @include transition(all, .2);
        }
      }

      &.active {
        a {
          &:after {
            width: 100%;
          }
        }
      }
    }
  }
}

.main-nav-dropdown {
  background: #fff;
  @include rem(padding, 48px 0);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  //border-top: 1px solid #e3e3e3;
  box-shadow: 0 0 20px 0 rgba(0,0,0,.2);
  overflow: hidden;
  display: none;

  &.alt {
    background: #fff;
    border-top: 1px solid #ececec;
  }

  &.active {
    display: block;
    animation: showDropdown .5s;
  }

  .head {
    .h4 {
      font-weight: 600;
      @include rem(margin-bottom, 20px);

      a {
        display: flex;
        align-items: center;

        .icon {
          width: 12px;
          height: 12px;
          //background: #000;
          margin-left: 10px;
          left: 0;
          @include transition(all, .3);
        }

        &:hover {
          .icon {
            left: 5px;
          }
        }
      }
    }
  }

  ul {
    li {
      line-height: 1.5em;
      position: relative;
      @include rem(padding-left, 20px);
      @include rem(margin-bottom, 16px);

      &:before {
        content: '';
        position: absolute;
        left: 0;
        @include rem(top, 8px);
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: #000;
      }
    }
  }

  a {
    color: #000;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .more-link {
    color: $red !important;
  }
}

.breadcrumbs {
  @include rem(padding, 24px 0);

  ul {
    display: flex;
    flex-wrap: wrap;
    @include rem(margin, 0 -15px);
  }

  li {
    @include rem(font-size, 15px);
    font-weight: 600;
    @include rem(padding, 2px 15px);
    margin: 0;
    position: relative;
    color: #8b8e8e;

    &:after {
      content: '';
      position: absolute;
      right: 0;
      @include rem(top, 11px);
      width: 1px;
      @include rem(height, 14px);
      background: #8b8e8e;
    }

    a {
      color: $yellow;
      text-decoration: underline;
      @include transition(all, .3);

      &:hover {
        text-decoration: none;
        color: #fff;
      }
    }

    &.current {
      a {
        color: #8b8e8e;
      }
    }

    &:last-child {
      &:after {
        content: none;
      }
    }
  }
}

.teaser-hp {
  @include rem(padding-top, 50px);
  @include rem(padding-bottom, 80px);

  .teaser-content {
    display: flex;
  }

  .text {
    @include rem(flex, 0 0 480px);
    @include rem(max-width, 480px);
    position: relative;
    z-index: 20;
    display: flex;
    align-items: center;

    .cat-list {
      @include rem(margin-bottom, 30px);
    }

    .head {
      //@include rem(margin-right, -100px);
    }

    h2 {
      color: #000;
      @include rem(margin-bottom, 50px);

      a {
        color: #000;
        text-decoration: none;

        &:hover {
          //color: $red;
          text-decoration: underline;
        }
      }

      .more {
        @include rem(width, 40px);
        @include rem(height, 40px);
        @include rem(margin-left, 10px);
        @include rem(top, -10px);
        border-radius: 50%;
        border: 1px solid #959595;
        background: #f7f7f7;
        color: $red;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .icon {
          width: 13px;
          height: 13px;
          flex: 0 0 13px;
        }
      }
    }
  }

  .img {
    margin-right: -156px;
    display: flex;
    align-items: center;
  }

  .desc {
    @include rem(font-size, 22px)
    line-height: 1.5em;
    color: #707070;
  }
}

.teaser-nav {
  @include rem(padding-top, 50px);

  ul {
    counter-reset: counter;

    li {
      @include rem(font-size, 17px);
      font-weight: 600;
      line-height: 1.3em;
      counter-increment: counter;
      margin-top: 20px;
      position: relative;
      @include rem(margin-bottom, 60px);

      a {
        display: block;
        border-top: 2px solid #e1e1e1;
        padding-top: 20px;
        color: #707070;
        text-decoration: none;
        position: relative;
        @include transition(all, .5);

        .line {
          position: absolute;
          left: 0;
          top: -2px;
          //width: 100%;
          height: 2px;
          background: $red;
        }

        &:before {
          font-size: 14px;
          font-weight: 600;
          content: "0" counter(counter);
          position: absolute;
          left: 0;
          top: -40px;
          color: #707070;
        }

        &:hover {
          color: $red;
        }
      }

      &.glide__bullet--active {
        a {
          color: #000;

          .line {
            width: 100%;
            animation: loadSlide 5s;
          }
        }
      }
    }
  }
}

.nav-btn {
  display: none;
  vertical-align: top;
  width: 30px;
  height: 30px;
  text-align: center;
  position: relative;
  z-index: 2001;
  cursor: pointer;
  align-items: center;
  @include rem(margin-left, 30px);

  .lines {
    width: 30px;
    height: 4px;
    flex: 0 0 30px;
    display: block;
    position: relative;
    position: absolute;
    top: 50%;
    margin-top: -2px;
  }

  .line-1,
  .line-2,
  .line-3 {
    display: block;
    width: 100%;
    height: 2px;
    background: #000;
    transition: 0.1s;
    position: absolute;
    left: 0;
    content: '';
    transform-origin: 0.28571rem center;
  }

  .line-1 {
    top: 0;
    transition: opacity 0.1s 0.1s ease;
  }

  .line-2 {
    top: 10px;
    transform-origin: 50% 50%;
    transition: top 0.1s 0.1s ease, -webkit-transform 0.1s ease;
    transition: top 0.1s 0.1s ease, transform 0.1s ease;
  }

  .line-3 {
    top: -10px;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    transition: top 0.1s 0.1s ease, -webkit-transform 0.1s ease;
    transition: top 0.1s 0.1s ease, transform 0.1s ease;
  }
}

.mobile-nav {
  background: #fff;
  width: 100%;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 90;
  overflow-y: auto;
  max-height: 100%;
  @include rem(padding, 130px 0 30px 0);
  @include transition(all, .3);
  @include translate(0,-100%);

  ul {
    margin: 0;
    padding: 0;

    li {
      @include rem(font-size, 15px);
      line-height: 1.3em;
      font-weight: 700;
      text-transform: uppercase;
      padding: 0;
      margin: 0;
      border-bottom: 1px solid #ebebeb;

      &.active {
        a {
          color: #ffa70e;
        }
      }

      &:last-child {
        border: none;
      }

      a {
        color: #000;
        text-decoration: none;
        display: inline-flex;
        align-items: center;
        @include rem(padding, 23px 62px 23px 44px);
        position: relative;
        width: 100%;

        .more {
          width: 52px;
          height: 100%;
          position: absolute;
          right: 0;
          top: 0;
          background: rgba(0,0,0,.025);
          border-left: 1px solid rgba(0,0,0,.1);

          &:after {
            content: '';
            width: 6px;
            height: 6px;
            display: block;
            border-left: 2px solid #000;
            border-bottom: 2px solid #000;
            @include rotate(-45deg);
            @include transition(all, .3);
            position: absolute;
            left: 50%;
            top: 50%;
            margin-left: -3px;
            margin-top: -3px;
          }
        }

        &.active {
          .more {
            &:after {
              @include rotate(135deg);
            }
          }
        }

        .icon {
          position: absolute;
          @include rem(left, 12px);
          @include rem(top, 22px);
          width: 16px;
          height: 16px;
          //background: #000;
        }
      }

      ul {
        display: none;

        &.active {
          display: block;
        }

        li {
          text-transform: none;
        }
      }
    }
  }
}

.mobile-nav-overlay {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 80;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0);
  display: none;
  @include transition(all, .5);
}

.mobile-nav-wrap {
  display: none;
}

body.mobile-nav-opened {
  overflow: hidden;
  
  .mobile-nav {    
    @include translate(0,0);
  }

  .nav-btn {
    .line-1 {
      transition: opacity 0.1s 0s ease;
      background: transparent;
      opacity: 0;
    }

    .line-2 {
      transform: rotate3d(0, 0, 1, 45deg);
      transition: top 0.1s ease, transform 0.1s 0.1s ease;
      top: 0;
    }

    .line-3 {
      transform: rotate3d(0, 0, 1, -45deg);
      transition: top 0.1s ease, transform 0.1s 0.1s ease;
      top: 0;
    }

    &:hover {
      .line-1,
      .line-2,
      .line-3 {
        background: #000;
      }
    }
  }

  .mobile-nav-overlay {
    display: block;
    background: rgba(0,0,0,0.9);
  }
}

.suggests {
  .item-list {
    @include rem(margin-top, -20px);
    @include rem(margin-bottom, -20px);
  }

  .item {
    @include rem(font-size, 14px);
    @include rem(margin, 20px 0);

    ul {
      li {
        line-height: 1.8em;
        margin: 0 0 10px 0;
        padding: 0;

        &:before {
          content: none;
        }
      }
    }

    .more {
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 600;
      display: flex;
      align-items: center;
      white-space: nowrap;
      text-decoration: none;
      color: $red;

      &:hover {
        text-decoration: underline;
      }

      .icon {
        width: 12px;
        height: 12px;
        margin-left: 10px;
      }
    }
  }
}

@keyframes showDropdown {
  0% {
    display: none;
    @include translate(0,-100%);
  }
  5% {
    display: block;
    @include translate(0,-100%);
  }
  100% {
    display: block;
    @include translate(0,0);
  }
}

@keyframes hideDropdown {
  0% {
    display: block;
    @include translate(0,0);
  }
  95% {
    display: block;
    @include translate(0,-100%);
  }  
  100% {
    display: none;
    @include translate(0,-100%);
  }
}

@keyframes showSearch {
  0% {
    display: none;
    max-width: 0;
  }
  5% {
    display: block;
    max-width: 0;
  }
  100% {
    display: block;
    max-width: 648px;
  }
}

@keyframes loadSlide {
  0% {
    max-width: 0;
  }
  100% {
    max-width: 100%;
  }
}