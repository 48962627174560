.loader-overlay {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.3);
	z-index: 100;
}

.spinner {
	font-size: 30px;
	position: absolute;
	display: inline-block;
	width: 1em;
	height: 1em;
	left: 50%;
	top: 50%;
	z-index: 1;
}

.spinner div {
	position: absolute;
	left: 0.4629em;
	bottom: 0;
	width: 0.074em;
	height: 0.2777em;
	border-radius: 0.5em;
	background-color: transparent;
	-webkit-transform-origin: center -0.2222em;
	-ms-transform-origin: center -0.2222em;
	transform-origin: center -0.2222em;
	-webkit-animation: spinner-fade 1s infinite linear;
	animation: spinner-fade 1s infinite linear;
}

.spinner div:nth-child(1) {
	-webkit-animation-delay: 0s;
	animation-delay: 0s;
	-webkit-transform: rotate(0deg);
	-ms-transform: rotate(0deg);
	transform: rotate(0deg);
}

.spinner div:nth-child(2) {
	-webkit-animation-delay: 0.083s;
	animation-delay: 0.083s;
	-webkit-transform: rotate(30deg);
	-ms-transform: rotate(30deg);
	transform: rotate(30deg);
}

.spinner div:nth-child(3) {
	-webkit-animation-delay: 0.166s;
	animation-delay: 0.166s;
	-webkit-transform: rotate(60deg);
	-ms-transform: rotate(60deg);
	transform: rotate(60deg);
}

.spinner div:nth-child(4) {
	-webkit-animation-delay: 0.249s;
	animation-delay: 0.249s;
	-webkit-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
}

.spinner div:nth-child(5) {
	-webkit-animation-delay: 0.332s;
	animation-delay: 0.332s;
	-webkit-transform: rotate(120deg);
	-ms-transform: rotate(120deg);
	transform: rotate(120deg);
}

.spinner div:nth-child(6) {
	-webkit-animation-delay: 0.415s;
	animation-delay: 0.415s;
	-webkit-transform: rotate(150deg);
	-ms-transform: rotate(150deg);
	transform: rotate(150deg);
}

.spinner div:nth-child(7) {
	-webkit-animation-delay: 0.498s;
	animation-delay: 0.498s;
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
}

.spinner div:nth-child(8) {
	-webkit-animation-delay: 0.581s;
	animation-delay: 0.581s;
	-webkit-transform: rotate(210deg);
	-ms-transform: rotate(210deg);
	transform: rotate(210deg);
}

.spinner div:nth-child(9) {
	-webkit-animation-delay: 0.664s;
	animation-delay: 0.664s;
	-webkit-transform: rotate(240deg);
	-ms-transform: rotate(240deg);
	transform: rotate(240deg);
}

.spinner div:nth-child(10) {
	-webkit-animation-delay: 0.747s;
	animation-delay: 0.747s;
	-webkit-transform: rotate(270deg);
	-ms-transform: rotate(270deg);
	transform: rotate(270deg);
}

.spinner div:nth-child(11) {
	-webkit-animation-delay: 0.83s;
	animation-delay: 0.83s;
	-webkit-transform: rotate(300deg);
	-ms-transform: rotate(300deg);
	transform: rotate(300deg);
}

.spinner div:nth-child(12) {
	-webkit-animation-delay: 0.913s;
	animation-delay: 0.913s;
	-webkit-transform: rotate(330deg);
	-ms-transform: rotate(330deg);
	transform: rotate(330deg);
}

@-webkit-keyframes spinner-fade {
	0% {
		background-color: #69717d;
	}
	100% {
		background-color: transparent;
	}
}

@keyframes spinner-fade {
	0% {
		background-color: #69717d;
	}
	100% {
		background-color: transparent;
	}
}